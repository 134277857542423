import axios from "axios";

const baseURL = process.env.REACT_APP_SERVER_URL;
// const scopes = [
//   'User.Read'
//   // 'openid',
//   // 'offline_access',
//   // 'profile',
//   // 'email',
//   // 'https://xxxx.onmicrosoft.com/xxxxxxxxxxxxx/Files.Read'
// ];

const Api = (function () {
  const axiosInstance = axios.create({
    baseURL: baseURL,
  });

  // const tryRelogin = (msalInstance) => {
  //   if (msalInstance) {
  //     msalInstance.logoutPopup();
  //     setTimeout(() => { console.log('logging in via popup after 2 second wait...'); msalInstance.loginPopup(); }, 2000);
  //   }
  // }

  // const fetchToken = async (msalInstance) => {
  //   if (!msalInstance) return null;

  //   try {
  //     let activeAccount = msalInstance.getActiveAccount();
  //     if (!activeAccount && msalInstance.getAllAccounts().length > 0) {
  //       let allAccounts = msalInstance.getAllAccounts();
  //       msalInstance.setActiveAccount(allAccounts[0]);
  //     }
  //     const token = await msalInstance.acquireTokenSilent({
  //       scopes: scopes,
  //     });
  //     return token.accessToken;
  //   } catch (error) {
  //     if (msalInstance) {
  //       tryRelogin(msalInstance);
  //     } else {
  //       console.log('no relogin: msalInstance is null');
  //     }
  //   }
  // }

  const handleResponse = (response, onSuccess, onFailure, msalInstance) => {
    if (response.status === 200) {
      onSuccess(response.data);
      // } else if (response.status === 401 && msalInstance) {
      //   tryRelogin(msalInstance);
    } else {
      onFailure(response);
    }
  };

  const makeRequest = async (
    method,
    url,
    data,
    isMaxLength,
    isFormData,
    onSuccess,
    onFailure,
    msalInstance
  ) => {
    try {
      // const token = await fetchToken(msalInstance);
      const headers = isFormData
        ? { "Content-Type": "multipart/form-data" }
        : {};
      // if (token) {
      //   headers['Authorization'] = `Bearer ${token}`;
      // }
      if (isMaxLength) {
        headers["maxBodyLength"] = 52428800; //50mb is overkill, but prevents issues
      }
      // console.log('url', url);
      // console.log('headers', headers);
      const response = await method(url, data, { headers });
      handleResponse(response, onSuccess, onFailure, msalInstance);
    } catch (error) {
      onFailure(error);
    }
  };

  return {
    GET: (url, params, onSuccess, onFailure, msalInstance) =>
      makeRequest(
        axiosInstance.get,
        url,
        { params },
        false,
        false,
        onSuccess,
        onFailure,
        msalInstance
      ),
    POST: (
      url,
      data,
      isMaxLength,
      isFormData,
      onSuccess,
      onFailure,
      msalInstance
    ) =>
      makeRequest(
        axiosInstance.post,
        url,
        data,
        isMaxLength,
        isFormData,
        onSuccess,
        onFailure,
        msalInstance
      ),
    PUT: (url, data, isFormData, onSuccess, onFailure, msalInstance) =>
      makeRequest(
        axiosInstance.put,
        url,
        data,
        false,
        isFormData,
        onSuccess,
        onFailure,
        msalInstance
      ),
    PATCH: (url, data, isFormData, onSuccess, onFailure, msalInstance) =>
      makeRequest(
        axiosInstance.patch,
        url,
        data,
        false,
        isFormData,
        onSuccess,
        onFailure,
        msalInstance
      ),
    DELETE: (url, onSuccess, onFailure, msalInstance) =>
      makeRequest(
        axiosInstance.delete,
        url,
        null,
        false,
        false,
        onSuccess,
        onFailure,
        msalInstance
      ),
  };
})();

export default Api;
