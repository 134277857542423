const yardLayout = {
  cars: {},

  tracks: {
    "Track 3": {
      id: "Track 3",
      title: "Track 3",
      carlength: "9",
      simulator_id: "3",
      carIds: [],
    },
    "Track 4": {
      id: "Track 4",
      title: "Track 4",
      carlength: "1",
      simulator_id: "4",
      carIds: [],
    },
    "Track 5": {
      id: "Track 5",
      title: "Track 5",
      carlength: "10",
      simulator_id: "5",
      carIds: [],
    },
    "Track 6": {
      id: "Track 6",
      title: "Track 6",
      carlength: "10",
      simulator_id: "6",
      carIds: [],
    },
    "Track 7": {
      id: "Track 7",
      title: "Track 7",
      carlength: "12",
      simulator_id: "7",
      carIds: [],
    },
    "Track 8": {
      id: "Track 8",
      title: "Track 8",
      carlength: "17",
      simulator_id: "8",
      carIds: [],
    },
    "Track 9": {
      id: "Track 9",
      title: "Track 9",
      carlength: "18",
      simulator_id: "9",
      carIds: [],
    },
    "North Lead": {
      id: "North Lead",
      title: "North Lead",
      carlength: "16",
      simulator_id: "NL",
      carIds: [],
    },
    "Track 10": {
      id: "Track 10",
      title: "Track 10",
      carlength: "11",
      simulator_id: "10",
      carIds: [],
    },
    "Track 11 Stub": {
      id: "Track 11 Stub",
      title: "Track 11 Stub",
      carlength: "10",
      simulator_id: "11s",
      carIds: [],
    },
    "Track 11": {
      id: "Track 11",
      title: "Track 11",
      carlength: "11",
      simulator_id: "11",
      carIds: [],
    },
    "Track 12": {
      id: "Track 12",
      title: "Track 12",
      carlength: "19",
      simulator_id: "12",
      carIds: [],
    },
    "Track 13": {
      id: "Track 13",
      title: "Track 13",
      carlength: "15",
      simulator_id: "13",
      carIds: [],
    },
    "Track 14": {
      id: "Track 14",
      title: "Track 14",
      carlength: "15",
      simulator_id: "14",
      carIds: [],
    },
    "Track 15": {
      id: "Track 15",
      title: "Track 15",
      carlength: "15",
      simulator_id: "15",
      carIds: [],
    },
    "Track 16": {
      id: "Track 16",
      title: "Track 16",
      carlength: "12",
      simulator_id: "16",
      carIds: [],
    },
    "Track 17": {
      id: "Track 17",
      title: "Track 17",
      carlength: "12",
      simulator_id: "17",
      carIds: [],
    },
    "Track 18": {
      id: "Track 18",
      title: "Track 18",
      carlength: "13",
      simulator_id: "18",
      carIds: [],
    },
    "Track 19": {
      id: "Track 19",
      title: "Track 19",
      carlength: "11",
      simulator_id: "19",
      carIds: [],
    },
    "Track 20": {
      id: "Track 20",
      title: "Track 20",
      carlength: "10",
      simulator_id: "20",
      carIds: [],
    },
    "Track 21": {
      id: "Track 21",
      title: "Track 21",
      carlength: "10",
      simulator_id: "21",
      carIds: [],
    },
    "Track 22": {
      id: "Track 22",
      title: "Track 22",
      carlength: "9",
      simulator_id: "22",
      carIds: [],
    },
    "Track 23": {
      id: "Track 23",
      title: "Track 23",
      carlength: "8",
      simulator_id: "23",
      carIds: [],
    },
    "Track 24": {
      id: "Track 24",
      title: "Track 24",
      carlength: "11",
      simulator_id: "24",
      carIds: [],
    },
    "Track 25": {
      id: "Track 25",
      title: "Track 25",
      carlength: "9",
      simulator_id: "25",
      carIds: [],
    },
    "Track 26": {
      id: "Track 26",
      title: "Track 26",
      carlength: "13",
      simulator_id: "26",
      carIds: [],
    },
    "Track 27": {
      id: "Track 27",
      title: "Track 27",
      carlength: "12",
      simulator_id: "27",
      carIds: [],
    },
    "Track 28": {
      id: "Track 28",
      title: "Track 28",
      carlength: "14",
      simulator_id: "28",
      carIds: [],
    },
    "Track 29": {
      id: "Track 29",
      title: "Track 29",
      carlength: "16",
      simulator_id: "29",
      carIds: [],
    },
    "Track 30": {
      id: "Track 30",
      title: "Track 30",
      carlength: "10",
      simulator_id: "30",
      carIds: [],
    },
    "Track 31": {
      id: "Track 31",
      title: "Track 31",
      carlength: "10",
      simulator_id: "31",
      carIds: [],
    },
    "Track 32": {
      id: "Track 32",
      title: "Track 32",
      carlength: "10",
      simulator_id: "32",
      carIds: [],
    },
    "Track 33": {
      id: "Track 33",
      title: "Track 33",
      carlength: "10",
      simulator_id: "33",
      carIds: [],
    },
    "Track 34": {
      id: "Track 34",
      title: "Track 34",
      carlength: "11",
      simulator_id: "34",
      carIds: [],
    },
    "Track 35": {
      id: "Track 35",
      title: "Track 35",
      carlength: "11",
      simulator_id: "35",
      carIds: [],
    },
    "S1 Spur": {
      id: "S1 Spur",
      title: "S1 Spur",
      carlength: "14",
      simulator_id: "S1",
      carIds: [],
    },
    "S2 Spur": {
      id: "S2 Spur",
      title: "S2 Spur",
      carlength: "12",
      simulator_id: "S2",
      carIds: [],
    },
    "LAT West": {
      id: "LAT West",
      title: "LAT West",
      carlength: "12",
      simulator_id: "LW",
      carIds: [],
    },
    "LAT East": {
      id: "LAT East",
      title: "LAT East",
      carlength: "13",
      simulator_id: "LE",
      carIds: [],
    },
    "Fence Track": {
      id: "Fence Track",
      title: "Fence Track",
      carlength: "13",
      simulator_id: "FT",
      carIds: [],
    },
    "Centre Track": {
      id: "Centre Track",
      title: "Centre Track",
      carlength: "11",
      simulator_id: "CT",
      carIds: [],
    },
    "CP Main": {
      id: "CP Main",
      title: "CP Main",
      carlength: "",
      simulator_id: "CP",
      carIds: [],
    },
    Runaround: {
      id: "Runaround",
      title: "Runaround",
      carlength: "26",
      simulator_id: "Runaround",
      carIds: [],
    },

    "On North Engine": {
      id: "On North Engine",
      title: "On North Engine",
      carlength: "20",
      carIds: [],
      isEngine: true,
      train_id: 0,
    },
    "On South Engine": {
      id: "On South Engine",
      title: "On South Engine",
      carlength: "20",
      carIds: [],
      isEngine: true,
      train_id: 1,
    },
    "Yard B Engine": {
      id: "Yard B Engine",
      title: "Yard B Engine",
      carlength: "20",
      carIds: [],
      isEngine: true,
      train_id: 2,
    },
    "Yard C Engine": {
      id: "Yard C Engine",
      title: "Yard C Engine",
      carlength: "20",
      carIds: [],
      isEngine: true,
      train_id: 3,
    },
  },

  yards: {
    "Yard A North": {
      id: "Yard A North",
      visible: true,
      trackIds: [
        "On North Engine",
        "Track 3",
        "Track 4",
        "Track 5",
        "Track 6",
        "Track 7",
        "Track 8",
        "Track 9",
        "Track 10",
        "Track 11",
        "Track 11 Stub",
        "Track 12",
        "Track 13",
        "Track 14",
        "North Lead",
      ],
    },
    "Yard A South": {
      id: "Yard A South",
      visible: true,
      trackIds: [
        "On South Engine",
        "Track 15",
        "Track 16",
        "Track 17",
        "Track 18",
        "Track 19",
        "Track 20",
        "Track 21",
        "Track 22",
        "Track 23",
        "Track 24",
      ],
    },
    "Yard B": {
      id: "Yard B",
      visible: true,
      trackIds: [
        "Yard B Engine",
        "Track 25",
        "Track 26",
        "Track 27",
        "Track 28",
        "Track 29",
        "Track 30",
        "Track 31",
        "Track 32",
        "Track 33",
        "Track 34",
        "Track 35",
      ],
    },
    "Yard C": {
      id: "Yard C",
      visible: true,
      trackIds: [
        "Yard C Engine",
        "S1 Spur",
        "S2 Spur",
        "LAT West",
        "LAT East",
        "Fence Track",
        "Centre Track",
        "CP Main",
        "Runaround",
      ],
    },
  },

  racks: {
    "Rack 1": {
      id: "Rack 1",
      number: "1",
      racklength: "6",
      trackIds: ["Track 10", "Track 11"],
    },
    "Rack 3": {
      id: "Rack 3",
      number: "3",
      racklength: "12",
      trackIds: ["Track 12", "Track 13"],
    },
    "Rack 4": {
      id: "Rack 4",
      number: "4",
      racklength: "5",
      trackIds: ["Track 16", "Track 17"],
    },
    Rack: {
      id: "Rack",
      number: "Lube",
      racklength: "5",
      trackIds: ["Track 20", "Track 21"],
    },
    "Rack 5": {
      id: "Rack 5",
      number: "5",
      racklength: "5",
      trackIds: ["Track 22", "Track 23"],
    },
    "Rack 7": {
      id: "Rack 7",
      number: "7",
      racklength: "6",
      trackIds: ["Track 25", "Track 26"],
    },
  },

  yardANorthTrackOrder: [
    "On North Engine",
    "Track 3",
    "Track 4",
    "Track 5",
    "Track 6",
    "Track 7",
    "Track 8",
    "Track 9",
    "Track 10",
    "Track 11",
    "Track 11 Stub",
    "Track 12",
    "Track 13",
    "Track 14",
    "North Lead",
  ],
  yardASouthTrackOrder: [
    "On South Engine",
    "Track 15",
    "Track 16",
    "Track 17",
    "Track 18",
    "Track 19",
    "Track 20",
    "Track 21",
    "Track 22",
    "Track 23",
    "Track 24",
  ],
  yardBTrackOrder: [
    "Yard B Engine",
    "Track 25",
    "Track 26",
    "Track 27",
    "Track 28",
    "Track 29",
    "Track 30",
    "Track 31",
    "Track 32",
    "Track 33",
    "Track 34",
    "Track 35",
  ],
  yardCTrackOrder: [
    "Yard C Engine",
    "S1 Spur",
    "S2 Spur",
    "LAT West",
    "LAT East",
    "Fence Track",
    "Centre Track",
    "CP Main",
    "Runaround",
  ],

  yardEngineIds: {
    "Yard A North": 0,
    "Yard A South": 1,
    "Yard B": 2,
    "Yard C": 3,
  },

  initialYardFile: "",
  resultsFile: "",
  viewResults: false,
  simResultIds: [],
};

export default yardLayout;
