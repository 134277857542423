import React, { useState, useEffect } from "react";
import App from "./main";

function Auth(props) {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const response = await fetch("/.auth/me");
      // console.log("response", response);
      const payload = await response.json();
      const { clientPrincipal } = payload;
      return clientPrincipal;
    };
    fetchCurrentUser()
      .then((response) => {
        if (response !== null && response !== undefined) {
          // console.log("auth response: ", response);
          setLoggedIn(true);
        } else {
          console.log("not logged in");
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  return loggedIn || window.location.href.includes("localhost") ? (
    <App />
  ) : (
    <div style={{ margin: "20px" }}>
      <a className="blueButton" href="/login">
        Login to the Edmonton Rail Yard
      </a>
    </div>
  );
}

export default Auth;
