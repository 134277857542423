import React from "react";
import styled from "styled-components";
import { Flex, Group, MultiSelect, Select, Stack, Text } from "@mantine/core";
import fetchFileMetadata from "./file-info-fetcher";
import fetchFileContents from "./file-contents-fetcher";
import Api from "./Api";
import SimMultiRequest from "./sim-multi-request";
const { date2str } = require("./date-format");

const SimulatorRequestDetails = styled.div`
  display: inline;
  margin: 0 8px 0 8px;
  font-size: 12px;
`;

export default class SimRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simRequestSourceYards: [],
      simRequestTargetYard: this.props.yardNames?.[0] ?? "",
      simRequestTargetTrack: this.props.tracks?.[0] ?? "",
      latestSimRequestDate: "",
      availableTargetTracks: [],
      actualTracks: [],
      baysOnTargetTrack: [],
      // waitingForSimResults: false,
      orders: [],
      elapsedSeconds: 0,
      resultsCompleteETA: Number(
        process.env.REACT_APP_YARD_FILE_LOAD_ETA_SECONDS
      ),
      maxWaitSeconds: Number(
        process.env.REACT_APP_YARD_FILE_LOAD_MAX_WAIT_SECONDS
      ),
    };
  }

  componentDidMount() {
    this.setLatestRequestFileDate();
    // const tracksWithRacks = this.getTracksWithRacks();

    const newAvailableTargetTracks = [];
    this.getTracksWithRacks().map((track) => {
      let m = track.match("^Track \\d+$");
      if (m && m[0] === m.input) {
        newAvailableTargetTracks.push(track);
      }
      return newAvailableTargetTracks;
    });

    const actualTrackNames = [];
    Object.values(this.props.tracks)
      ?.filter((x) => (x.isEngine ?? false) === false)
      ?.map((track) => {
        actualTrackNames.push(track.simulator_id);
        return actualTrackNames;
      });

    const newState = {
      ...this.state,
      availableTargetTracks: newAvailableTargetTracks,
      actualTracks: actualTrackNames,
    };
    this.setState(newState);
  }

  simRequestSourceYardsChanged = (e) => {
    const newState = {
      ...this.state,
      simRequestSourceYards: e,
    };
    this.setState(newState);
    // console.log("source yards changed", e);
  };

  simRequestTargetYardChanged = (e) => {
    const newState = {
      ...this.state,
      simRequestTargetYard: e,
    };
    this.setState(newState);
  };

  simRequestTrackChanged = (e) => {
    const bays = [];
    const foundRack = Object.values(this.props.racks).filter((x) =>
      x.trackIds.includes(e)
    )?.[0];
    if (foundRack) {
      for (let i = 1; i <= Number(foundRack.racklength); i++) {
        bays.push(i.toString());
      }
    }

    const newState = {
      ...this.state,
      simRequestTargetTrack: e,
      baysOnTargetTrack: bays,
    };
    this.setState(newState);
  };

  getTracksWithRacks = () => {
    const keys = Object.keys(this.props.racks);
    let trackList = [];
    keys.forEach((rack) => {
      trackList = trackList.concat(this.props.racks[rack].trackIds);
    });

    return trackList;
  };

  setLatestRequestFileDate = async () => {
    try {
      const filePath =
        process.env.REACT_APP_SIM_REQUEST_FILE_FOLDER +
        process.env.REACT_APP_SIM_REQUEST_FILE_NAME;
      const fileResponse = await fetchFileMetadata(
        filePath,
        this.props.msalInstance
      );

      if (fileResponse) {
        const dateStr = date2str(
          new Date(fileResponse.lastModified),
          "yyyy-MM-dd hh:mm:ss"
        );

        const newState = {
          ...this.state,
          latestSimRequestDate: dateStr,
        };
        this.setState(newState);
        this.props.onSimRequestFound(dateStr);
      }
    } catch (error) {
      alert("Error fetching files", error);
    }
  };

  requestMultiSim = async (simRequestList) => {
    const orders = [];
    let closedBayInfo = {};
    simRequestList.forEach((req) => {
      if (req.targetTrack) {
        orders.push(req.targetTrack);
        closedBayInfo = {
          ...closedBayInfo,
          [req.targetTrack]: req.closedBaysOnTargetTrack,
        };
      }
    });
    if (orders.length > 0) {
      await this.requestSimFromServer(orders, closedBayInfo);
    }

    const newState = {
      ...this.state,
      orders: orders,
      resultsCompleteETA:
        Number(process.env.REACT_APP_YARD_FILE_LOAD_ETA_SECONDS) *
          orders?.length ?? 1,
    };
    this.setState(newState);

    // const track = Object.values(this.props.tracks).find(x => x.id === this.state.simRequestTargetTrack);
    // const bayInfo = {
    //     [track.simulator_id]: e,
    // };
    // this.props.disabledBaysChanged(bayInfo);
  };

  requestSimFromServer = async (orders, closedBayInfo) => {
    const requestURL =
      process.env.REACT_APP_SERVER_URL +
      process.env.REACT_APP_REQUEST_SIMULATION_SERVICE;
    const request = {
      requestDateTime: new Date(),
      sourceYard: this.state.simRequestSourceYards,
      targetYard: this.state.simRequestTargetYard,
      orders: orders,
      disabledTracks: this.props.disabledTracks,
      disabledBays: closedBayInfo,
      racks: this.props.racks,
      cars: this.props.cars,
      tracks: this.props.tracks,
    };

    await Api.POST(
      requestURL,
      request,
      true,
      false,
      (data) => {
        const isoFileDateStr = data.fileDate;
        const localDate = new Date(isoFileDateStr);
        const dateStr = date2str(localDate, "yyyy-MM-dd hh:mm:ss");

        const newState = {
          ...this.state,
          latestSimRequestDate: dateStr,
        };
        this.setState(newState);
        this.props.onSimRequestFound(dateStr);

        this.waitForSimResults(data.fileDate, orders);
      },
      (err) => {
        console.log("err", err);
        alert("Error requesting simulator results: ", err.message);
      },
      this.props.msalInstance
    );
  };

  waitForSimResults = async (initialRequestFileDate, orders) => {
    const resultsFileName =
      process.env.REACT_APP_SIM_RESULTS_FILE_FOLDER +
      process.env.REACT_APP_SIM_RESULTS_FILE_NAME;

    let lastDate = initialRequestFileDate;
    let fileResponse = null;

    const startTime = new Date();
    let simStatus = "Running";
    // console.log(
    //   "orders",
    //   orders,
    //   "Time calc",
    //   Number(process.env.REACT_APP_SIM_RESPONSE_ETA_SECONDS) * orders?.length ??
    //     1
    // );
    this.props.simulatorRunning(
      false,
      simStatus,
      Number(process.env.REACT_APP_SIM_RESPONSE_ETA_SECONDS) * orders?.length ??
        1
    );

    try {
      while (lastDate <= initialRequestFileDate) {
        //check to see if we exceeded the maximum wait time
        const runningWaitTime = Math.round((new Date() - startTime) / 1000);
        const newETA = Math.max(
          0,
          (Number(process.env.REACT_APP_SIM_RESPONSE_ETA_SECONDS) *
            orders?.length ?? 1) - runningWaitTime
        );
        const newState = {
          ...this.state,
          elapsedSeconds: runningWaitTime,
          resultsCompleteETA: newETA,
          // waitingForSimResults: true,
        };
        this.setState(newState);

        this.props.simulatorRunning(true, "Running", newETA); //state isn't updated yet, need this value

        if (runningWaitTime > this.state.maxWaitSeconds) {
          throw new Error(
            "The simulator did not complete within the maximum wait time.  If this persists, please contact support."
          );
        }

        fileResponse = await fetchFileMetadata(
          resultsFileName,
          this.props.msalInstance
        );
        lastDate = fileResponse.lastModified;

        await this.sleep(5000);
      }

      if (fileResponse) {
        const fileLoc =
          process.env.REACT_APP_SERVER_URL +
          process.env.REACT_APP_SIM_RESULTS_SERVICE;
        const fileData = await fetchFileContents(
          fileLoc,
          resultsFileName,
          process.env.REACT_APP_SIM_RESULTS_FILE_NAME,
          this.props.msalInstance
        );

        const foundDate = fileResponse
          ? new Date(Date.parse(fileResponse.lastModified))
          : null;
        const dateString = foundDate
          ? date2str(foundDate, "yyyy-MM-dd hh:mm:ss")
          : "";

        const fileInfo = {
          contents: fileData,
          lastUpdate: dateString ?? "",
        };
        this.props.onSimRequestComplete(fileInfo);
        simStatus = "Complete";
      }
    } catch (error) {
      alert("Error running the simulator: " + error);
      simStatus = "Error";
    } finally {
      this.props.simulatorRunning(false, simStatus, 0);
    }
  };

  sleep = (ms) => {
    return new Promise((resolve) => setInterval(resolve, ms));
  };

  disabledBaysChanged = (e) => {
    const track = Object.values(this.props.tracks).find(
      (x) => x.id === this.state.simRequestTargetTrack
    );
    const bayInfo = {
      [track.simulator_id]: e,
    };
    this.props.disabledBaysChanged(bayInfo);
  };

  render() {
    return (
      <Stack align="flex-start" justify="flex-start" gap="xs">
        <Text size="xl">Simulation Request</Text>
        <Group>
          <MultiSelect
            onChange={this.simRequestSourceYardsChanged}
            allowDeselect={false}
            label="Source Yard(s):"
            required={true}
            data={this.props.yardNames}
            placeholder="Select Yard(s)"
            size="xs"
            className="inputField"
            comboboxProps={{ withinPortal: false, placeholder: "Source Yards" }}
          ></MultiSelect>

          <Select
            onChange={this.simRequestTargetYardChanged}
            allowDeselect={false}
            label="Target Yard:"
            required={true}
            data={this.props.yardNames}
            placeholder="Select Yard"
            size="xs"
            value={this.state?.simRequestTargetYard ?? null}
          ></Select>
        </Group>
        <Flex justify="flex-start" align="flex-end">
          <MultiSelect
            onChange={this.props.disabledTracksChanged}
            label="Closed Tracks:"
            data={this.state?.actualTracks}
            placeholder="Tracks"
            comboboxProps={{ withinPortal: false, placeholder: "Tracks" }}
            size="xs"
          ></MultiSelect>
          <SimMultiRequest
            yards={this.props.yards}
            yardNames={this.props.yardNames}
            tracks={this.props.tracks}
            racks={this.props.racks}
            requestSim={this.requestMultiSim}
            targetYard={this.state?.simRequestTargetYard}
            isSimulatorRunning={this.props.isSimulatorRunning}
          ></SimMultiRequest>
        </Flex>

        <SimulatorRequestDetails>
          {/* Last Simulation Request Time: {this.state.latestSimRequestDate} */}
        </SimulatorRequestDetails>
      </Stack>
    );
  }
}
